export default class Mail {

    constructor( element ) {

        this.element = element;

        this.body;
        this.documentElement;
        this.bottom;
        this.top;

        // this.randomX;
        // this.randomX;


        this.dropObjects = Array.from(this.element.children);

        this.initMail();

    }

    initMail() {
        this.documentElement = document.documentElement;
        this.body = document.body;
        this.setRandomDrop();

        this.body.onscroll = ( event ) => { this.updatePosition( event ); };
        this.updatePosition();

    }

    updatePosition() {
        this.bottom = this.element.getBoundingClientRect().bottom;
        this.top = this.element.getBoundingClientRect().top;

        if (this.top < window.innerHeight && this.bottom < window.innerHeight) {
            this.element.setAttribute('data-in-view', 'true');
        }
    }

    setRandomDrop() {

        this.dropObjects.forEach((element) => {
            var max = ((window.innerWidth - 140) / 3) - element.getBoundingClientRect().width;
            var randomX = this.getRandomInt(max);
            var randomSpeed = Math.random() * 25;
            element.style.setProperty("--posX", randomX + "px"); 
            element.style.setProperty("--speed", randomSpeed + "s");
        } ) 

    }

    getRandomInt(max) {
         return Math.floor(Math.random() * max);
      }

}