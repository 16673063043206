export default class Menu {

    constructor( element ) {
        this.element = element;
        
        this.hamburger = document.querySelector('[data-hamburger]');
        this.hamburger.addEventListener( 'click', ( event ) => { this.toggleMenu(); } )
        this.main = document.querySelector('main');
        this.body = document.querySelector('body');
    }

    toggleMenu() {
       
        this.element.classList.toggle('visible');
        this.hamburger.classList.toggle('opened');
        this.main.classList.toggle('hide');
        this.body.classList.toggle('no-scroll');

    }
    
}