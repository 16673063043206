import lozad from 'lozad';

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();



import Mail from './components/mail';
import Menu from './components/menu.js';

const mailElement = document.querySelector( '[data-mail]' );
const menuElement = document.querySelector('[data-menu]');


if ( mailElement ) {
    const mail = new Mail( mailElement );
}

if ( menuElement ) {
    const menu = new Menu (menuElement);
}